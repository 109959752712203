<template>
  <div class="grg_page_prensa">
    <section class="grg_section_principal">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center p-0">
            <div class="grg_container_titulo">
              <div
                class="grg_container_titulo_pagina"
                style="padding: 30px 0 15px 0px"
              >
                <h1
                  class="
                    grg_font_outline grg_font_family_rb grg_title_medium
                    text-uppercase
                    m-0
                  "
                  style="
                    --color_solid: #000;
                    --color_outline: #000;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Kit de Prensa
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 
        <section class="grg_section_descripcion_prensa">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 p-0">
                        <div> 
                            <p class="grg_font_family_reb grg_text_small text-center m-0">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum ea temporibus dolor, nulla eum impedit consectetur dignissimos aperiam ab repellat quasi deserunt adipisci est distinctio dolore ratione, unde, explicabo aspernatur. Totam nulla libero animi accusantium fuga saepe tenetur earum voluptates.
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section> -->

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 p-0">
            <div class="grg_container_botones_marcas">
              <div @click="consultarArchivosToks()">
                <div class="grg_mapa_logo_marca_bullets">
                  <img
                    class="grg_imagenes_logos_cuadrados"
                    src="@/assets/img/prensa/toks.svg"
                    width="80px"
                    alt=""
                  />
                </div>
                <div class="grg_prensa_bullets"></div>
              </div>
              <div @click="consultarArchivosShakeShack()">
                <div class="grg_mapa_logo_marca_bullets">
                  <img
                    class="grg_imagenes_logos_rectangulos"
                    src="@/assets/img/prensa/shake_shack.svg"
                    width="140px"
                    alt=""
                  />
                </div>
                <div class="grg_prensa_bullets"></div>
              </div>
              <!-- <div @click="consultarArchivosBeerFactory()">
                <div class="grg_mapa_logo_marca_bullets">
                  <img
                    class="grg_imagenes_logos_cuadrados"
                    src="@/assets/img/prensa/beer_factory.svg"
                    width="80px"
                    alt=""
                  />
                </div>
                <div class="grg_prensa_bullets"></div>
              </div> -->
              <div @click="consultarArchivosElFarolito()">
                <div class="grg_mapa_logo_marca_bullets">
                  <img
                    class="grg_imagenes_logos_rectangulos"
                    src="@/assets/img/prensa/el_farolito.svg"
                    width="140px"
                    alt=""
                  />
                </div>
                <div class="grg_prensa_bullets"></div>
              </div>
              <div @click="consultarArchivosPandaExpress()">
                <div class="grg_mapa_logo_marca_bullets">
                  <img
                    class="grg_imagenes_logos_cuadrados"
                    src="@/assets/img/prensa/panda_express.svg"
                    width="80px"
                    alt=""
                  />
                </div>
                <div class="grg_prensa_bullets"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="padding: 0px 0 30px 0px">
      <div class="container">
        <div class="row">
          <div
            class="col-sm-12 text-center"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
            "
          >
            <div
              class="grg_container_logos_prensa"
              style="
                min-height: 160px;
                min-width: 400px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
              "
            >
              <img
                :width="width_logo"
                :src="obtenerImagenesLogo(logo)"
                alt=""
              />
            </div>
            <div>
              <div
                style="
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  padding-bottom: 20px;
                "
              >
                <a
                  :href="documento1"
                  target="_blank"
                  class="d-flex"
                  @mouseover="hovered = 1"
                  @mouseleave="hovered = null"
                >
                  <img
                    class="imagen_off"
                    style="margin-right: 30px"
                    width="20px"
                    alt=""
                    :src="hovered === 1 ? img.green : img.grey"
                  />
                  <p class="m-0 text-dark">Logotipo .AI</p>
                </a>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  padding-bottom: 20px;
                "
              >
                <a
                  :href="documento2"
                  target="_blank"
                  class="d-flex"
                  @mouseover="hovered = 2"
                  @mouseleave="hovered = null"
                >
                  <img
                    class="imagen_off"
                    style="margin-right: 30px"
                    width="20px"
                    alt=""
                    :src="hovered === 2 ? img.green : img.grey"
                  />
                  <p class="m-0 text-dark">Logotipo .EPS</p>
                </a>
              </div>
              <!-- <div style="display: flex;justify-content: left;align-items: center;">
                                 <a :href="documento3" target="_blank">
                                         <img class="imagen_off" style="margin-right:30px" width="20px" alt="" :src="hovered === 3 ? img.green : img.grey" 
                                        @mouseover="hovered = 3"
                                        @mouseleave="hovered = null">
                                    </a>
                                <p class="m-0">Media Kit</p>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Prensa",
  data() {
    return {
      logo: "",
      width_logo: "",
      documento1: "",
      documento2: "",
      documento3: "",
      hovered: null,
      img: {
        grey: require("@/assets/img/prensa/bajar_off.svg"),
        green: require("@/assets/img/prensa/bajar_on.svg"),
      },
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    activarBullet(id) {
      this.bullets_mapa_marcas = document.querySelectorAll(
        ".grg_prensa_bullets"
      );
      for (var i = 0; i < this.bullets_mapa_marcas.length; i++) {
        if (i === id) {
          this.bullets_mapa_marcas[i].classList.add(
            "grg_prensa_bullets_active"
          );
        } else {
          this.bullets_mapa_marcas[i].classList.remove(
            "grg_prensa_bullets_active"
          );
        }
      }
      // this.bullets_mapa_marcas[id].classList.remove("grg_mapa_bullets_active");
    },
    consultarArchivosGenerales(marcaRecibida) {
      const baseURI =
        this.$baseURL + "grg-admin/index.php/prensa/get_document_prensa";
      this.$http
        .get(
          baseURI,
          {
            params: {
              token: localStorage.getItem("token"),
              marca: marcaRecibida,
            },
          },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          // console.log(res.data.documentos);
          this.documento3 = this.$baseURL + res.data.documentos[0].documento;
          this.documento2 = this.$baseURL + res.data.documentos[1].documento;
          this.documento1 = this.$baseURL + res.data.documentos[2].documento;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    consultarArchivosToks() {
      this.activarBullet(0);
      this.logo = "toks.svg";
      this.width_logo = "160px";
      // console.log("Trabajando APIS de Consulta TOKS")
      this.consultarArchivosGenerales("TOKS");
    },
    consultarArchivosShakeShack() {
      this.activarBullet(1);
      this.logo = "shake_shack.svg";
      this.width_logo = "250px";
      // console.log("Trabajando APIS de Consulta SHAKE")
      this.consultarArchivosGenerales("SHAKE SHACK");
    },
    // consultarArchivosBeerFactory() {
    //   this.activarBullet(2);
    //   this.logo = "beer_factory.svg";
    //   this.width_logo = "140px";
    //   // console.log("Trabajando APIS de Consulta BEER FACTORY")
    //   this.consultarArchivosGenerales("BEER FACTORY & FOOD");
    // },
    consultarArchivosElFarolito() {
      this.activarBullet(2);
      this.logo = "el_farolito.svg";
      this.width_logo = "250px";
      // console.log("Trabajando APIS de Consulta EL FAROLITO")
      this.consultarArchivosGenerales("EL FAROLITO");
    },
    consultarArchivosPandaExpress() {
      this.activarBullet(3);
      this.logo = "panda_express.svg";
      this.width_logo = "140px";
      // console.log("Trabajando APIS de Consulta PANDA EXPRESS")
      this.consultarArchivosGenerales("PANDA EXPRESS");
    },
    obtenerImagenesLogo(imagen) {
      if (imagen != "") {
        return require("@/assets/img/prensa/" + imagen);
      }
    },
  },
  mounted() {
    this.consultarArchivosToks();
  },
  created() {
    this.grgFuncionChangeTransparencia(false);
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Prensa`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.row {
    margin: 0 -15px;
}
.grg_container_botones_prensa {
  background-color: pink;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.grg_container_boton_logo {
  background-color: yellow;
}

.grg_container_botones_marcas {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.grg_container_botones_marcas > div {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 15px 15px;
}
.grg_prensa_bullets {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px #737373 solid;
  background-color: transparent;
  opacity: 1;
}
.grg_mapa_logo_marca_bullets {
  height: calc(100% - 25px);
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_prensa_bullets_active {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* margin-right: 2px;
    margin-left: 2px; */
  border: 1px #fff solid;
  background-color: #b82039;
  opacity: 1;
}
.imagen_off {
  cursor: pointer;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
  .grg_imagenes_logos_cuadrados {
    width: 60px;
  }
  .grg_imagenes_logos_rectangulos {
    width: 110px;
  }
}
@media (max-width: 575.98px) {
  .grg_container_logos_prensa {
    min-width: 100% !important;
  }
}
</style>