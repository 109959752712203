<template>
  <div class="grg_page_quienes_somos">
    <section class="grg_section_principal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 grg_container_principal">
            <div class="w-100">
              <img
                class="img-fluid"
                width="100%"
                src="@/assets/img/quienes_somos/portada.webp"
                alt=""
              />
              <div class="grg_container_trasparencia"></div>
            </div>
            <div class="grg_container_titulo_pagina text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_big
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Nuestra Historia
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_descripcion">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="grg_container_texto_historia">
              <p class="grg_text_medium">
                <span
                  class="
                    grg_font_outline
                    grg_first_letter
                    grg_font_family_reb
                    grg_title_bigger
                    text-uppercase
                  "
                  style="
                    --color_solid: #000;
                    --color_outline: #000;
                    --pos_x: 0.05em;
                    --pos_y: 0.05em;
                    line-height: 0.5;
                  "
                  >s</span
                >omos una empresa mexicana que forma parte del consorcio de
                Grupo Gigante. Desde 1971 iniciamos operaciones a través de
                Restaurantes Toks y gracias a nuestra pasión por el servicio,
                atención al cliente y enfoque en las personas hemos mantenido un
                crecimiento constante, consolidándonos como un grupo con amplia
                experiencia y dominio en la industria gastronómica de nuestro
                país.
              </p>
              <p class="grg_text_medium">
                Hoy en día contamos con más de 250 restaurantes en México en formatos de:
                Restaurante cafetería:<strong> Toks</strong>, Fast food:
                <strong>Panda Express</strong>, Fine casual:
                <strong>Shake Shack</strong> y Restaurante de especialidad:
                <strong>El Farolito</strong>. Representando con orgullo marcas mexicanas y
                extranjeras que comparten nuestros valores y compromiso con la sociedad.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grg_section_objetivo grg_container_color"
      style="--background_color: #b1a090"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="grg_container_imagen_hambre">
              <div class="position-relative grg_container_aro">
                <img
                  class="img-fluid rounded-circle"
                  width="350px"
                  src="@/assets/img/quienes_somos/hambre_de_crecer.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 grg_p_1">
            <div class="grg_container_texto_parrafo_hambre w-100 h-100">
              <p class="grg_text_medium">
                <span
                  class="
                    grg_font_outline
                    grg_first_letter
                    grg_font_family_reb
                    grg_title_bigger
                    text-uppercase
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: 0.05em;
                    line-height: 0.4;
                  "
                  >N</span
                >uestro objetivo de negocio es crecer en todas nuestras marcas y
                seguir
                <strong>sumando conceptos atractivos de restaurantes</strong>,
                que ayuden a diversificar nuestro portafolio, que nos consoliden
                como grupo y a ser más relevantes ante nuestros clientes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_nuestros_valores">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="grg_container_titulo text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Nuestros Valores
              </h1>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="container-fluid">
              <div class="row grg_container_valores text-center">
                <div
                  v-for="valor of valores"
                  :key="valor.id"
                  class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3"
                >
                  <div class="grg_container_valor">
                    <div
                      class="grg_container_letra_text"
                      style="flex-direction: column"
                    >
                      <div>
                        <h1
                          class="
                            grg_elemento_letra_valores grg_font_family_rb
                            text-uppercase
                          "
                          style="
                            --pos_x: -0.05em;
                            --pos_y: 0.05em;
                            --font_size: 100px;
                          "
                          :style="{ '--color_solido': valor.color }"
                          :data-letra="valor.letra"
                        >
                          {{ valor.letra }}
                          <div class="grg_container_image_icon_valor">
                            <img
                              :src="obtenerImagenIconoValor(valor.icono)"
                              alt=""
                            />
                          </div>
                        </h1>
                      </div>
                      <div>
                        <h1
                          class="
                            grg_text_extra_small grg_font_family_rb
                            text-uppercase
                          "
                          data-font="valores"
                        >
                          {{ valor.valor }}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_nuestra_historia">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="grg_container_titulo text-center">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Nuestra historia
              </h1>
            </div>
          </div>

          <div class="col-12 grg_container_fechas">
            <div
              v-for="fecha of fechas"
              :key="fecha.id"
              class="grg_container_fecha"
              :style="{
                '--color_primario': fecha.color,
                '--color_secundario': obtenerColorSegundo(fecha.id),
              }"
              :data-aos="fecha.animacion"
            >
              <div class="grg_container_datos_periodo">
                <div class="grg_container_datos text-center">
                  <div class="grg_container_year">
                    <div
                      :class="{
                        grg_conatiner_linea_vertical_superior: fecha.id == 1,
                      }"
                    ></div>
                    <h1
                      class="grg_title_small grg_font_family_rb m-0"
                      style="color: #616161"
                    >
                      {{ fecha.year }}
                    </h1>
                  </div>
                  <div class="grg_container_descripcion position-relative">
                    <div class="grg_container_circulo"></div>
                    <div class="grg_conatiner_linea_horizontal"></div>
                    <div class="grg_container_linea_vertical_inferior"></div>
                    <div
                      v-if="fecha.descripcion1 != ''"
                      style="padding: 10px 0"
                    >
                      <h1
                        class="grg_text_medium grg_font_family_rb m-0"
                        style="color: #616161"
                        v-html="fecha.descripcion1"
                      ></h1>
                    </div>
                    <div
                      v-if="fecha.descripcion2 != ''"
                      style="padding: 10px 0"
                    >
                      <h1
                        class="grg_text_medium grg_font_family_rb m-0"
                        style="color: #616161"
                        v-html="fecha.descripcion2"
                      ></h1>
                    </div>
                    <div
                      v-if="fecha.descripcion3 != ''"
                      style="padding: 10px 0"
                    >
                      <h1
                        class="grg_text_medium grg_font_family_rb m-0"
                        style="color: #616161"
                        v-html="fecha.descripcion3"
                      ></h1>
                    </div>
                  </div>
                </div>
                <div class="grg_container_imagen_periodo text-center">
                  <div class="grg_container_imagen_fecha">
                    <div
                      class="
                        grg_container_imagen_fecha_comodin
                        position-relative
                      "
                    >
                      <img
                        class="rounded-circle img-fluid grg_img_fecha"
                        :src="obtenerImagenPrincipalLineaTiempo(fecha.imagen)"
                        alt=""
                      />
                      <div
                        :class="{ 'd-none': fecha.imagen2 == '' }"
                        class="grg_container_imagen_superior"
                        :style="{ 'text-align': fecha.position_logo }"
                      >
                        <div>
                          <img
                            :width="fecha.width_logo"
                            class="img-fluid"
                            :src="
                              obtenerImagenPrincipalLineaTiempo(fecha.imagen2)
                            "
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>





              <div v-show="fecha.tipo_presentacion == 2" class="grg_container_datos_periodo">
                <div class="grg_container_datos text-center">
                  <!-- <div class="grg_container_year">
                    <div
                      :class="{
                        grg_conatiner_linea_vertical_superior: fecha.id == 1,
                      }"
                    ></div>
                    <h1
                      class="grg_title_small grg_font_family_rb m-0"
                      style="color: #616161"
                    >
                      {{ fecha.year }}
                    </h1>
                  </div> -->
                  <div class="grg_container_descripcion position-relative">
                    <!-- <div class="grg_container_circulo"></div> -->
                    <!-- <div class="grg_conatiner_linea_horizontal"></div> -->
                    <div class="grg_container_linea_vertical_inferior"></div>
                    <div
                      v-if="fecha.objeto2_descripcion1 != ''"
                      style="padding: 10px 0"
                    >
                      <h1
                        class="grg_text_medium grg_font_family_rb m-0"
                        style="color: #616161"
                        v-html="fecha.objeto2_descripcion1"
                      ></h1>
                    </div>
                    <div
                      v-if="fecha.objeto2_descripcion2 != ''"
                      style="padding: 10px 0"
                    >
                      <h1
                        class="grg_text_medium grg_font_family_rb m-0"
                        style="color: #616161"
                        v-html="fecha.objeto2_descripcion2"
                      ></h1>
                    </div>
                    <div
                      v-if="fecha.objeto2_descripcion3 != ''"
                      style="padding: 10px 0"
                    >
                      <h1
                        class="grg_text_medium grg_font_family_rb m-0"
                        style="color: #616161"
                        v-html="fecha.objeto2_descripcion3"
                      ></h1>
                    </div>
                  </div>
                </div>
                <div class="grg_container_imagen_periodo text-center">
                  <div class="grg_container_imagen_fecha">
                    <div
                      class="
                        grg_container_imagen_fecha_comodin
                        position-relative
                      "
                    >
                      <img
                        class="rounded-circle img-fluid grg_img_fecha"
                        :src="obtenerImagenPrincipalLineaTiempo(fecha.objeto2_imagen)"
                        alt=""
                      />
                      <div
                        :class="{ 'd-none': fecha.objeto2_imagen2 == '' }"
                        class="grg_container_imagen_superior"
                        :style="{ 'text-align': fecha.objeto2_position_logo }"
                      >
                        <div>
                          <img
                            :width="fecha.objeto2_width_logo"
                            class="img-fluid"
                            :src="
                              obtenerImagenPrincipalLineaTiempo(fecha.objeto2_imagen2)
                            "
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>












            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "QuienesSomos",
  data() {
    return {
      fechas: [
        {
          id: 1,
          year: "1971",
          color: "#3F3C37",
          imagen: "1971.webp",
          imagen2: "toks.webp",
          width_logo: "40%",
          position_logo: "right",
          opcion: false,
          animacion: "fade-left",
          descripcion1:
            "Se inaugura el primer restaurante tipo cafetería bajo la marca Toks.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 2,
          year: "2003",
          color: "#726D69",
          imagen: "2003.webp",
          imagen2: "",
          width_logo: "",
          position_logo: "right",
          opcion: false,
          animacion: "fade-right",
          descripcion1: "Inician operaciones del Comisariato.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 3,
          year: "2004",
          color: "#A39A91",
          imagen: "2004.webp",
          imagen2: "2004_tumbnail.webp",
          width_logo: "25%",
          position_logo: "right",
          opcion: false,
          animacion: "fade-up",
          descripcion1:
            "Restaurantes Toks incursiona como operador de estacionamientos bajo el nombre comercial SET.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 4,
          year: "2008",
          color: "#D3C8B6",
          imagen: "2008.webp",
          imagen2: "",
          width_logo: "40%",
          position_logo: "left",
          opcion: false,
          animacion: "fade-down",
          descripcion1: "Se crea el Instituto Toks.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 5,
          year: "2011",
          color: "#EEE1D0",
          imagen: "2011.webp",
          imagen2: "panda.webp",
          width_logo: "40%",
          position_logo: "right",
          opcion: false,
          animacion: "fade-up-left",
          descripcion1:
            "Se inaugura el primer restaurante Panda Express en la <br>Ciudad de México.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 6,
          year: "2012",
          color: "#F4DA9D",
          imagen: "2012.webp",
          imagen2: "2012_tumbnail.webp",
          width_logo: "25%",
          position_logo: "left",
          opcion: false,
          animacion: "fade-up-right",
          descripcion1: "Se abre la primera cafetería bajo la marca Cup Stop.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 7,
          year: "2014",
          color: "#F7D05B",
          imagen: "2014.webp",
          imagen2: "beer_factory.svg",
          width_logo: "65%",
          position_logo: "center",
          opcion: true,
          animacion: "fade-down-left",
          descripcion1:
            "Se adquiere la totalidad de las acciones de Restaurantes California, S.A. de C.V., propietarias de las marcas California & Beer Factory.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 8,
          year: "2015",
          color: "#F4DA9D",
          imagen: "2015.webp",
          imagen2: "toks.webp",
          width_logo: "40%",
          position_logo: "left",
          opcion: false,
          animacion: "fade-down-right",
          descripcion1: "Se transforman las 46 unidades de California en Toks.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 9,
          year: "2016",
          color: "#EEE1D0",
          imagen: "2016.webp",
          imagen2: "",
          width_logo: "",
          position_logo: "left",
          opcion: false,
          animacion: "fade-left",
          descripcion1: "Se estrenan oficinas en Miyana.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 10,
          year: "2017",
          color: "#D3C7B7",
          imagen: "2017.webp",
          imagen2: "",
          width_logo: "",
          position_logo: "left",
          opcion: false,
          animacion: "fade-right",
          descripcion1: "Se transforman las unidades Wings en Toks.",
          descripcion2:
            "Se inaugura el Centro de Distribución de Operaciones Logísticas.",
          descripcion3: "Cierra operaciones Cup Stop.",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 11,
          year: "2018",
          color: "#A39A91",
          imagen: "2018.webp",
          imagen2: "toks.webp",
          width_logo: "40%",
          position_logo: "right",
          opcion: false,
          animacion: "fade-up",
          descripcion1:
            "Toks abre su restaurante número 200 y cambia su imagen.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 12,
          year: "2019",
          color: "#726D69",
          imagen: "2019.webp",
          imagen2: "shake_shack.svg",
          width_logo: "65%",
          position_logo: "center",
          opcion: true,
          animacion: "fade-down",
          descripcion1:
            "Se abre el primer restaurante Shake Shack en la<br>Ciudad de México.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 13,
          year: "2020",
          color: "#3F3C37",
          imagen: "2020.webp",
          imagen2: "logo_el_farolito.webp",
          width_logo: "65%",
          position_logo: "center",
          opcion: true,
          animacion: "fade-down-left",
          descripcion1: "El Farolito se suma al portafolio de la empresa.",
          descripcion2:
            'Se consolida el nombre comercial del grupo <img src="/img/grg_negro.d8dc6e4b.svg" alt="" class="img-fluid" width="100px">',
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '',
          objeto2_imagen2: "",
        },
        {
          id: 14,
          year: "2021",
          color: "#3F3C37",
          imagen: "2021.webp",
          imagen2: "toks50.webp",
          width_logo: "50%",
          position_logo: "left",
          opcion: true,
          animacion: "fade-down-left",
          descripcion1:
            "¡Toks cumple 50 años! 50 Años DeToks, 50 Años De Todos.",
          descripcion2: "",
          descripcion3: "",
          tipo_presentacion: 2,
          objeto2_descripcion1: "¡Panda Express cumple 10 años en México!.",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: '2021_1.webp',
          objeto2_imagen2: "panda.webp",
          objeto2_width_logo: "40%",
          objeto2_position_logo: "left",
        },
        {
          id: 15,
          year: "2024",
          color: "#3F3C37",
          imagen: "2024.jpg",
          imagen2: "beer_factory.svg",
          width_logo: "50%",
          position_logo: "left",
          opcion: true,
          animacion: "fade-down-left",
          descripcion1:
            " “BeerFactory & Food” cierra sus sucursales pero continúa con la venta de cerveza embotellada.",
          descripcion2: "Gracias por tantas experiencias únicas",
          descripcion3: "",
          tipo_presentacion: 1,
          objeto2_descripcion1: "",
          objeto2_descripcion2: "",
          objeto2_descripcion3: "",
          objeto2_imagen: "",
          objeto2_imagen2: "",
          objeto2_width_logo: "",
          objeto2_position_logo: "",
        },
      ],
      valores: [
        {
          id: 1,
          letra: "L",
          valor: "Liderazgo",
          icono: "liderazgo_icon.webp",
          color: "#D3C8B6",
        },
        {
          id: 2,
          letra: "C",
          valor: "Congruencia",
          icono: "congruencia_icon.webp",
          color: "#A39A91",
        },
        {
          id: 3,
          letra: "C",
          valor: "Creatividad",
          icono: "creatividad_icon.webp",
          color: "#726D69",
        },
        {
          id: 4,
          letra: "R",
          valor: "Reconocimiento",
          icono: "reconocimiento_icon.webp",
          color: "#3F3C37",
        },
        {
          id: 5,
          letra: "E",
          valor: "Excelencia",
          icono: "excelencia_icon.webp",
          color: "#F7D05B",
        },
        {
          id: 6,
          letra: "I",
          valor: "Integración",
          icono: "integracion_icon.webp",
          color: "#F4DA9D",
        },
        {
          id: 7,
          letra: "H",
          valor: "Humanidad",
          icono: "humanidad_icon.webp",
          color: "#EDE2CE",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    obtenerImagenPrincipalLineaTiempo(imagen) {
      if (imagen != "") {
        return require("@/assets/img/linea_de_tiempo/" + imagen);
      }
    },
    obtenerColorSegundo(id) {
      if (id < this.fechas.length) {
        return this.fechas[id].color;
      } else {
        return "transparent";
      }
    },
    obtenerImagenIconoValor(icono) {
      if (icono != "") {
        return require("@/assets/img/nuestros_valores/" + icono);
      }
    },
  },
  computed: {
    ...mapState([
      "grg_var_scroll",
      "grg_var_view_port_md",
      "grg_var_transparencia",
    ]),
  },
  created() {
    this.grgFuncionChangeTransparencia(true);
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | ¿Quiénes Somos?`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.row {
    margin: 0 -15px;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
.grg_transparencia {
  filter: brightness(0.6);
}
.grg_container_trasparencia {
  background: linear-gradient(
    180deg,
    #0000 0%,
    #0000003b 24%,
    #000000e3 76%,
    #000,
    #000,
    #000
  );
  position: absolute;
  bottom: 0;
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
  z-index: 2;
}
.grg_container_principal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_titulo_pagina {
  position: absolute;
  margin: 1% 0;
  bottom: 0;
  z-index: 10;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */
.grg_section_descripcion {
  padding: 2rem 0;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo  */
.grg_section_objetivo {
  padding: 3rem 0;
}
.grg_container_imagen_hambre,
.grg_container_texto_parrafo_hambre {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_aro:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  top: -0.5rem;
  left: -0.5rem;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion nuestros valores  */
.grg_section_nuestros_valores {
  padding: 3rem 0;
}
.grg_container_valores,
.grg_container_letra_text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_valores > div {
  padding: 20px;
}
.grg_elemento_letra_valores {
  -webkit-text-stroke: 1.2px #000000;
  color: transparent;
  font-size: var(--font_size);
  position: relative;
  z-index: -1;
}
.grg_elemento_letra_valores[data-letra]:after {
  content: attr(data-letra);
  -webkit-text-stroke: 1px transparent;
  color: transparent;
  text-shadow: 0px 0px var(--color_solido);
  position: absolute;
  bottom: var(--pos_y);
  left: var(--pos_x);
  z-index: 1;
  width: 100%;
}
.grg_container_letra_text > div:nth-child(1) {
  position: relative;
  display: flex;
  justify-content: center;
}
.grg_container_letra_text > div:nth-child(1) h1 {
  width: 120px;
  border-radius: 10px;
  background-color: #f7f7f7;
}
.grg_container_image_icon_valor {
  position: absolute;
  width: 100%;
  top: -65px;
  text-align: left;
  margin-left: -40px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion nuestros valores */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
.grg_img_fecha {
  border: 6px solid var(--color_primario);
  /* width: 243px;
    height: 243px; */
}
.grg_container_imagen_periodo {
  display: flex;
  justify-content: center;
  text-align: center;
}
.grg_container_imagen_superior {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -20%;
}
.grg_section_nuestra_historia {
  overflow: hidden;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
  .grg_section_principal {
    margin-top: -95px;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */
  .grg_section_descripcion {
    padding: 3rem 4.5rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion descripcion */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo  */

  .grg_container_texto_parrafo_hambre {
    margin-left: -15%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion nuestros valores  */
  .grg_container_valores {
    width: 90%;
    margin: 0 auto;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion nuestros valores */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
  .grg_section_nuestra_historia {
    padding: 2rem 4rem !important;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
}
@media (min-width: 768px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo  */
  .grg_section_objetivo {
    padding: 3rem 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
  .grg_section_nuestra_historia {
    padding: 2rem 0rem;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
  .grg_container_fecha:last-child {
    padding: 0 0 6rem;
  }
  .grg_container_fecha {
    width: 50%;
    position: relative;
    margin: 0 0 -4rem 0;
  }
  .grg_container_fecha:nth-child(odd) {
    float: right;
    clear: left;
  }
  .grg_container_fecha:nth-child(even) {
    float: left;
    clear: right;
  }
  .grg_container_datos_periodo {
    display: flex;
  }
  .grg_container_fecha:nth-child(even) .grg_container_datos_periodo {
    flex-direction: row-reverse;
  }
  .grg_container_datos {
    width: 60%;
    height: 100%;
  }
  .grg_container_imagen_periodo {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grg_container_year {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grg_container_descripcion {
    padding: 2rem 1rem;
  }
  .grg_container_year,
  .grg_container_descripcion {
    height: 50%;
    height: 160px;
    min-height: 150px;
  }
  .grg_container_circulo {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 0%;
    transform: translateY(-50%);
  }
  .grg_container_fecha:nth-child(odd) .grg_container_circulo {
    background-color: var(--color_primario);
    left: calc(-30px);
  }
  .grg_container_fecha:nth-child(even) .grg_container_circulo {
    background-color: var(--color_primario);
    right: calc(-30px);
  }
  .grg_container_circulo:before {
    content: "";
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 6px solid;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  .grg_container_fecha:nth-child(odd) .grg_container_circulo:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    border-top-color: var(--color_primario);
    border-bottom-color: var(--color_secundario);
  }
  .grg_container_fecha:nth-child(even) .grg_container_circulo:before {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    border-top-color: var(--color_primario);
    border-bottom-color: var(--color_secundario);
  }
  .grg_container_circulo:after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    border-radius: 50%;
  }
  .grg_container_fecha:nth-child(odd) .grg_container_circulo:after {
    right: 70px;
    background-color: var(--color_secundario);
  }
  .grg_container_fecha:nth-child(even) .grg_container_circulo:after {
    left: 70px;
    background-color: var(--color_secundario);
  }
  .grg_conatiner_linea_horizontal {
    width: calc(100% - 25%);
    height: 6px;
    position: absolute;
    top: -3px;
  }
  .grg_container_fecha:nth-child(odd) .grg_conatiner_linea_horizontal {
    background-color: var(--color_primario);
    left: 44px;
  }
  .grg_container_fecha:nth-child(even) .grg_conatiner_linea_horizontal {
    background-color: var(--color_primario);
    right: 44px;
  }
  .grg_conatiner_linea_horizontal:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: -4.5px;
    border-radius: 50%;
  }
  .grg_container_fecha:nth-child(odd) .grg_conatiner_linea_horizontal:before {
    right: -1px;
    background-color: var(--color_primario);
  }
  .grg_container_fecha:nth-child(even) .grg_conatiner_linea_horizontal:before {
    left: -1px;
    background-color: var(--color_primario);
  }
  .grg_container_linea_vertical_inferior {
    height: calc(100% + 8px);
    width: 6px;
    position: absolute;
    bottom: -52px;
    z-index: 1;
  }
  .grg_container_fecha:nth-child(odd) .grg_container_linea_vertical_inferior {
    background-color: var(--color_secundario);
    left: -3px;
  }
  .grg_container_fecha:nth-child(even) .grg_container_linea_vertical_inferior {
    background-color: var(--color_secundario);
    right: -3px;
  }
  .grg_conatiner_linea_vertical_superior {
    width: 6px;
    height: calc(50% - 44px);
    position: absolute;
    top: 0px;
  }
  .grg_container_fecha:nth-child(odd) .grg_conatiner_linea_vertical_superior {
    background-color: var(--color_primario);
    left: -3px;
  }
  .grg_container_fecha:nth-child(even) .grg_conatiner_linea_vertical_superior {
    background-color: var(--color_primario);
    right: -3px;
  }
  .grg_container_fecha:first-child
    .grg_conatiner_linea_vertical_superior:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    right: -4.5px;
    top: -1px;
    background-color: var(--color_primario);
    border-radius: 50%;
  }
  .grg_img_fecha {
    max-width: 80%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo  */
  .grg_container_texto_parrafo_hambre {
    padding: 2rem 0 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo */
}
@media (max-width: 767.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
  .grg_container_imagen_periodo {
    padding: 4rem 0 5% 0;
  }
  .grg_container_imagen_superior {
    top: -15%;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para linea de tiempo */
}
@media (max-width: 575.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo  */
  .grg_container_aro img {
    width: 250px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion objetivo */
}
</style>