<template>
  <div class="grg_page_preguntas_frecuentes">
    <section
      class="grg_section_principal"
      style="background: linear-gradient(180deg, #00000066, white)"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="grg_container_titulo">
              <div
                class="grg_container_titulo_pagina"
                style="padding: 30px 0px"
              >
                <h1
                  class="
                    grg_font_outline grg_font_family_rb grg_title_medium
                    text-uppercase
                  "
                  style="
                    --color_solid: #000;
                    --color_outline: #000;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Preguntas Frecuentes
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_faqs">
      <div
        v-for="faqs of arrayFAqs"
        :key="faqs.item"
        class="grg_container_faqs"
      >
        <div
          class="p-4 text-center"
          v-show="faqs.titulo == '¿Qué es A Comer Club®?'"
          style="background-color: #000"
        >
          <img width="250px" src="@/assets/img/logos/a_comer_club.svg" alt="" />
        </div>
        <div style="background-color: #726d69; color: #58595b">
          <div class="container p-4">
            <div class="row">
              <div class="col-12 text-center">
                <div class="grg_container_titulo_faqs">
                  <p
                    class="grg_text_extra_big grg_font_family_rb m-0 text-white"
                    v-html="faqs.titulo"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="background-color: #fff; color: #58595b">
          <div class="container p-4">
            <div class="row">
              <div class="col-12">
                <div class="grg_container_descripcion_faqs">
                  <p
                    class="grg_text_medium grg_font_family_rr"
                    v-html="faqs.descripcion"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "PreguntasFrecuentes",
  data() {
    return {
      arrayFAqs: [
        {
          id: 1,
          titulo: '¿QUÉ ES GRG<span style="font-size:10px">®</span>?',
          descripcion:
            "Somos una empresa mexicana que forma parte del consorcio de Grupo Gigante. Desde 1971 iniciamos operaciones a través de Restaurantes Toks y gracias a nuestra pasión por el servicio, atención al cliente y enfoque en las personas hemos mantenido un crecimiento constante, consolidándonos como un grupo con amplia experiencia y dominio en la industria gastronómica de nuestro país.",
        },
        {
          id: 2,
          titulo:
            '¿Qué marcas conforman GRG<span style="font-size:10px">®</span>?',
          descripcion:
            '<p class="text-center">GRG<span style="font-size:10px">®</span> está conformado por cuatro marcas:<br><br><strong class="grg_font_family_rb"><a style="color:rgb(88, 89, 91)" target="_blank" href="https://www.toks.com.mx/">Restaurante cafetería: Toks</a><br><a style="color:rgb(88, 89, 91)" target="_blank" href="https://www.pandaexpress.com.mx/es/">Fast food: Panda Express</a><br><a style="color:rgb(88, 89, 91)" target="_blank" href="https://www.shakeshack.com.mx/">Fine casual: Shake Shack</a><br><a style="color:rgb(88, 89, 91)" target="_blank" href="https://elfarolito.com.mx/">Restaurante de especialidad: El Farolito</a></strong></p>',
        },
        
        // -------Código de beer Factory (va dentro de la descripcion de arriba --^ )--------
        // <br><a style="color:rgb(88, 89, 91)" target="_blank" href="https://www.beerfactory.com.mx/">Casual dining: Beer Factory and Food</a>

        {
          id: 3,
          titulo:
            '¿GRG<span style="font-size:10px">®</span> es parte de Grupo Gigante?',
          descripcion:
            'Si, GRG<span style="font-size:10px">®</span> y cada una de las marcas de este grupo restaurantero forman parte de Grupo Gigante. Si quieres conocer más sobre Grupo Gigante puedes visitar la página <strong><a style="color:#58595B" class="grg_font_family_reb" target="_blank" href="http://www.grupogigante.com.mx">www.grupogigante.com.mx</a></strong>',
        },
        {
          id: 4,
          titulo:
            "Me interesa mantener una franquicia de alguna de sus marcas, ¿se puede?",
          descripcion:
            "Por el momento no brindamos franquicias de ninguna de nuestras marcas.",
        },
        {
          id: 5,
          titulo:
            "Quiero formar parte de su equipo de trabajo, ¿dónde puedo consultar las vacantes?",
          descripcion:
            'Agradecemos tu interés en nuestras marcas. Las vacantes las puedes conocer en nuestra sección <a href="/produccion/nuestraGente"><strong style="color:#58595B" class="grg_font_family_reb">Nuestra Gente</strong></a>. No olvides seguirnos en <a target="_blank" href="https://www.facebook.com/GRGTalento"><strong style="color:#58595B" class="grg_font_family_reb">nuestra página de Facebook GRG Talento</strong></a>, Ahí también podrás conocer nuestras vacantes.',
        },
        {
          id: 6,
          titulo:
            "Tengo dudas y/o problemas sobre el proceso de facturación, ¿quién puede ayudarme?",
          descripcion:
            'Para dudas generales, manda tu email a <strong><a style="color:#58595B" class="grg_font_family_reb" href="mailto:contacto@grg.mx">contacto@grg.mx</a></strong><br>Si la factura que te gustaría tramitar y/o consultar va dirigida a alguna de nuestras marcas, visita su sitio web, ahí podrás darle seguimiento correspondiente.',
        },
        {
          id: 7,
          titulo:
            "¿Cómo puedo comunicarme con el servicio de atención al cliente?",
          descripcion:
            'Puedes hacerlo a través del numero: <strong class="grg_font_family_reb"><a style="color:#58595B" href="tel:+525521225727">5521225727</a></strong> o vía correo electrónico a <strong class="grg_font_family_reb"><a style="color:#58595B" class="grg_font_family_reb" href="mailto:contacto@grg.mx">contacto@grg.mx</a></strong>',
        },
        {
          id: 8,
          titulo:
            "Me interesa formar parte de su programa de responsabilidad social, ¿cómo puedo hacerlo?",
          descripcion:
            'Compártenos todos los detalles de tu proyecto al correo: <a style="color:#58595B" class="grg_font_family_reb" href="mailto:responsabilidadsocial@toks.com.mx">responsabilidadsocial@toks.com.mx</a>',
        },
        {
          id: 9,
          titulo: '¿Qué es A Comer Club<span style="font-size:10px">®</span>?',
          descripcion:
            '<strong class="grg_font_family_reb">A Comer Club<span style="font-size:10px">®</span></strong> es un programa de lealtad pensado en tus preferencias y necesidades, ofreciéndote grandes beneficios. Para ser miembro de A Comer Club<span style="font-size:10px">®</span> deberás regístrate en nuestra página web <strong><a style="color:#58595B" class="grg_font_family_reb" target="_blank" href="https://www.acomerclub.com.mx/home">www.AComerClub.com.mx</a></strong>, descargando la aplicación móvil A Comer Club<span style="font-size:10px">®</span> o llamando nuestro call center.',
        },
        {
          id: 10,
          titulo:
            '¿Qué establecimientos participan en A Comer Club<span style="font-size:10px">®</span>?',
          descripcion:
            'Las marcas participantes son <strong class="grg_font_family_reb">Toks</strong>, <strong class="grg_font_family_reb">Panda Express</strong>, y <strong class="grg_font_family_reb">El Farolito</strong>. Consulta horarios y establecimientos participantes en <strong><a style="color:#58595B" class="grg_font_family_reb" target="_blank" href="https://www.acomerclub.com.mx/home">www.AComerClub.com.mx</a></strong>',
        },
        // Código que va dentro de la descripcion de arriba: ---^
        // <strong class="grg_font_family_reb">Beer Factory & Food</strong>

      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
  },
  created() {
    this.grgFuncionChangeTransparencia(false);
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Preguntas Frecuentes`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GRGTalento",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style>
</style>
<style scoped>
.row {
    margin: 0 -15px;
}
</style>