<template>
  <div class="grg_page_nuestras_marcas" style="background-color: #2b2b2b">
    <section class="grg_section_principal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 grg_container_principal">
            <div>
              <div class="grg_container_trasparencia"></div>
              <Slider :sliders="sliders"></Slider>
            </div>
            <div class="grg_container_titulo_pagina grg_utileria_flechas_abajo">
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_big
                  text-uppercase
                "
                style="
                  --color_solid: #fff;
                  --color_outline: #fff;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Nuestras Marcas
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- data-aos="fade-up" :data-aos-anchor-placement="marca.animacion" -->
    <section class="grg_section_marcas">
      <div
        class="grg_container_ficha_marca position-relative"
        v-for="marca of marcas"
        :key="marca.id"
        :id="marca.id_contenedor"
        :class="{ grg_utileria_flechas_abajo: marca.id != marcas.length }"
        :style="{ 'z-index': marcas.length + 1 - marca.id + '!important' }"
      >
        <div :id="marca.id_contenedor">
          <div
            class="container-fluid"
            data-aos="fade-up"
            :data-aos-anchor-placement="marca.animacion"
          >
            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center"
              >
                <div
                  class="grg_container_imagen_platillo h-100 grg_p_6"
                  :class="{ grg_p_6_1: grg_var_view_port_md }"
                >
                  <div
                    class="
                      grg_container_imagen_platillo_comodin
                      position-relative
                    "
                  >
                    <img
                      class="rounded-circle img-fluid grg_img_principal_marca"
                      :style="{
                        'object-position': marca.position_imagen_marca,
                      }"
                      :src="obtenerImagenPrincipalMarca(marca.imagen_marca)"
                      alt=""
                    />
                    <div
                      class="grg_container_imagen_logo_marca"
                      :style="{ 'text-align': marca.position_logo_marca }"
                    >
                      <img
                        :width="marca.width_logo"
                        :src="obtenerlogo(marca.logo_marca)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <div class="grg_container_informacion_general">
                  <div class="grg_container_titulo_descripcion">
                    <h1
                      class="
                        grg_font_outline grg_font_family_rb grg_title_medium
                        text-uppercase
                      "
                      style="
                        --color_solid: #fff;
                        --color_outline: #fff;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                      "
                    >
                      {{ marca.nombre_marca }}
                    </h1>
                    <h1
                      class="grg_text_big grg_font_family_rb"
                      style="color: #fff"
                    >
                      {{ marca.descripcion }}
                    </h1>
                  </div>
                  <div class="grg_container_datos_globales">
                    <div
                      class="grg_container_datos"
                      v-if="marca.categoria != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_categoria.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          Categoría:
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.categoria }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div
                      class="grg_container_datos"
                      v-if="marca.inicio_operaciones != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_operaciones.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          Inicio de operaciones:
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.inicio_operaciones }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div
                      class="grg_container_datos"
                      v-if="marca.portafolio_grg != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_portafolio.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          Portafolio grg<span style="font-size: 10px">®</span>:
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.portafolio_grg }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div
                      class="grg_container_datos"
                      v-if="marca.numero_colaboradores != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_colaboradores.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          Número de colaboradores:
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.numero_colaboradores }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div
                      class="grg_container_datos"
                      v-if="marca.presencia != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_presencia.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          Presencia:
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.presencia }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div
                      class="grg_container_datos"
                      v-if="marca.platillos_estrella != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_signature.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          {{ obtenertextoSingular(marca.platillos_estrella) }}
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.platillos_estrella }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div
                      class="grg_container_datos"
                      v-if="marca.nuestras_cervezas != ''"
                    >
                      <div class="grg_container_icon_category">
                        <img
                          class=""
                          width="40px"
                          :src="
                            obtenerIconosDatos(
                              'icono_cervezas.svg',
                              marca.id % 2
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="grg_container_elemento_text_datos">
                        <h1
                          class="
                            grg_text_extra_small
                            text-uppercase
                            grg_font_family_reb
                          "
                        >
                          Nuestras cervezas:
                          <span
                            class="grg_text_medium grg_font_family_rb p-2"
                            style="color: #fff; text-transform: none"
                            >{{ marca.nuestras_cervezas }}</span
                          >
                        </h1>
                      </div>
                    </div>
                    <div class="grg_container_datos">
                      <div class="grg_container_redes_sociales_global w-100">
                        <div
                          class="
                            grg_container_elemento_texto_siguenos
                            position-relative
                          "
                        >
                          <h1
                            data-utileria="flechas_derecha"
                            class="grg_text_bigger grg_font_family_rb"
                            style="color: #fff; line-height: 1; margin: 0"
                          >
                            ¡Síguenos en redes!
                          </h1>
                        </div>
                        <div class="grg_container_redes_sociales">
                          <a :href="marca.link_instagram" target="_blank">
                            <img
                              class="grg_img_icon_redes"
                              src="../assets/img/iconos_marcas/red_instagram.svg"
                              alt=""
                            />
                          </a>
                          <a :href="marca.link_facebook" target="_blank">
                            <img
                              class="grg_img_icon_redes"
                              src="../assets/img/iconos_marcas/red_facebook.svg"
                              alt=""
                            />
                          </a>
                          <a :href="marca.link_twitter" target="_blank">
                            <img
                              class="grg_img_icon_redes"
                              src="../assets/img/iconos_marcas/red_twitter.svg"
                              alt=""
                            />
                          </a>
                          <a :href="marca.link_web" target="_blank">
                            <img
                              class="grg_img_icon_redes"
                              src="../assets/img/iconos_marcas/red_web.svg"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slider from "@/components/Slider.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "NuestrasMarcas",
  components: {
    Slider,
  },
  data() {
    return {
      sliders: [
        {
          id: 1,
          posicion: 0,
          nombre: "Slider toks",
          imagen: "/grg-admin/grg_sliders/carruseltoks.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        // {
        //   id: 2,
        //   posicion: 1,
        //   nombre: "Slider Beer",
        //   imagen: "/grg-admin/grg_sliders/carruselbeer.jpg",
        //   logo: "",
        //   width_logo: "",
        //   descripcion: "",
        // },
        {
          id: 3,
          posicion: 2,
          nombre: "Slider Panda",
          imagen: "/grg-admin/grg_sliders/carruselpanda.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 4,
          posicion: 3,
          nombre: "Slider Shake",
          imagen: "/grg-admin/grg_sliders/carruselshake.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 5,
          posicion: 4,
          nombre: "Slider Farolito",
          imagen: "/grg-admin/grg_sliders/carruselfarolito.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
      ],
      marcas: [
        {
          id: 1,
          id_contenedor: "toks",
          nombre_marca: "toks",
          imagen_marca: "platillo_toks.webp",
          position_imagen_marca: "center",
          logo_marca: "toks.webp",
          width_logo: "40%",
          position_logo_marca: "left",
          descripcion:
            "Un restaurante cafetería en el que podrás disfrutar de ingredientes frescos y de excelente calidad, instalaciones cómodas y modernas, productos artesanales de comercio justo y personal capacitado para dar el mejor servicio. Estar aquí, está mejor.",
          categoria: "Restaurante cafetería",
          inicio_operaciones: "1971",
          portafolio_grg: "",
          numero_colaboradores: "Más de 8,500",
          presencia:
            "Aguascalientes, Baja California, Campeche, Ciudad de México, Chiapas, Coahuila, Estado de México, Guanajuato, Guerrero, Hidalgo, Jalisco, Michoacán, Morelos, Nuevo León, Oaxaca, Puebla, Querétaro, Quintana Roo, San Luis Potosí, Tabasco, Veracruz y Yucatán",
          platillos_estrella:
            " Chilaquiles Mayas, Enchiladas de Mole, Asado Mixto, Big Chocolate Cake y nuestro delicioso café de comunidad, Toks a mano",
          nuestras_cervezas: "",
          link_instagram: "https://www.instagram.com/ToksMx/",
          link_facebook: "https://www.facebook.com/ToksMx",
          link_twitter: "https://twitter.com/ToksMx",
          link_web: "https://www.toks.com.mx/",
          animacion: "top-center",
        },
        {
          id: 2,
          id_contenedor: "beerfactory",
          nombre_marca: "Beer Factory",
          imagen_marca: "santa_fe.webp",
          position_imagen_marca: "center",
          logo_marca: "beer_factory.svg",
          width_logo: "80%",
          position_logo_marca: "center",
          descripcion:
            "Una auténtica fábrica de cerveza artesanal. Pioneros en México desde 1997.",
          categoria: "Cervecería artesanal",
          inicio_operaciones: "De 1997 al 2024",
          portafolio_grg: "2015",
          numero_colaboradores: "",
          presencia: "",
          platillos_estrella: "",
          nuestras_cervezas:
            "Luna Llena, Santa Fe, Coyote, Wannabe, Mil Culpas, Mediterránea Light, Ave de Mal Agüero y Alivianada",
          link_instagram: "https://www.instagram.com/beerfactorymx/",
          link_facebook: "https://www.facebook.com/beerfactorymx",
          link_twitter: "https://twitter.com/beerfactorymx",
          link_web: "https://www.beerfactory.com.mx/",
          animacion: "top-center",
        },
        {
          id: 3,
          id_contenedor: "pandaexpress",
          nombre_marca: "Panda Express",
          imagen_marca: "platillo_panda.webp",
          position_imagen_marca: "center",
          logo_marca: "panda_express.svg",
          width_logo: "35%",
          position_logo_marca: "left",
          descripcion:
            "Un restaurante líder en experiencias gastronómicas asiáticas donde podrás encontrar deliciosa cocina china-americana con audaces sabores e ingredientes frescos de alta calidad. Nuestra familia de clientes y colaboradores sigue siendo el centro de lo que hacemos.",
          categoria: "Fast food",
          inicio_operaciones: "1983",
          portafolio_grg: "2011",
          numero_colaboradores: "Más de 230",
          presencia: "Ciudad de México y Estado de México",
          platillos_estrella: "Orange Chicken",
          nuestras_cervezas: "",
          link_instagram: "https://www.instagram.com/pandaexpress_mexico/",
          link_facebook: "https://www.facebook.com/PandaExpressMx",
          link_twitter: "https://twitter.com/pandaexpressmx/",
          link_web: "https://www.pandaexpress.com.mx/",
          animacion: "top-center",
        },
        {
          id: 4,
          nombre_marca: "Shake Shack",
          id_contenedor: "shakeshack",
          imagen_marca: "platillo_shake.webp",
          position_imagen_marca: "center",
          logo_marca: "shake_shack.webp",
          width_logo: "80%",
          position_logo_marca: "center",
          descripcion:
            "Un lugar para disfrutar hamburguesas con carne Angus 100% natural – sin hormonas ni antibióticos, papas crinkle-cut, malteadas, cerveza, vino y más.",
          categoria: "Fine casual",
          inicio_operaciones: "2004",
          portafolio_grg: "2019",
          numero_colaboradores: "Más de 500",
          presencia: "Ciudad de México, Estado de México y Nuevo León",
          platillos_estrella: "ShackBurger",
          nuestras_cervezas: "",
          link_instagram: "https://www.instagram.com/shakeshackmx/",
          link_facebook: "https://www.facebook.com/shakeshackmx",
          link_twitter: "https://twitter.com/shakeshackmx",
          link_web: "https://www.shakeshack.com.mx/",
          animacion: "top-center",
        },
        {
          id: 5,
          id_contenedor: "elfarolito",
          nombre_marca: "El Farolito",
          imagen_marca: "platillo_farolito.webp",
          position_imagen_marca: "center",
          logo_marca: "el_farolito.svg",
          width_logo: "75%",
          position_logo_marca: "center",
          descripcion:
            'Una tradición en tacos. Pioneros en la preparación al carbón, tortillas recién "saliditas" del comal, variedad de salsas y la mejor calidad en cada ingrediente. Hablar de tacos en México es hablar de El Farolito.',
          categoria: "Restaurante de especialidad",
          inicio_operaciones: "1962",
          portafolio_grg: "2020",
          numero_colaboradores: "Más de 300",
          presencia: "Ciudad de México y Estado de México",
          platillos_estrella:
            "Tacos al Pastor, Volcán®, Farolada® y Colorines® aguas y jugos 100% naturales",
          nuestras_cervezas: "",
          link_instagram: "https://www.instagram.com/tacoselfarolito_mx/",
          link_facebook: "https://www.facebook.com/tacoselfarolito",
          link_twitter: "https://twitter.com/farolitooficial",
          link_web: "https://elfarolito.com.mx/",
          animacion: "top-center",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    obtenerImagenPrincipalMarca(imagen) {
      if (imagen != " ") {
        return require("../assets/img/marcas/" + imagen);
      }
    },
    obtenerlogo(imagen) {
      if (imagen != "") {
        return require("../assets/img/logos/" + imagen);
      }
    },
    obtenerIconosDatos(imagen, opcion) {
      if (imagen != "") {
        if (opcion === 1) {
          return require("../assets/img/iconos_marcas/" + imagen);
        } else if (opcion === 0) {
          return require("../assets/img/iconos_marcas/2" + imagen);
        }
      }
    },
    activarSlider() {
      $("#grg_slider_nuestras_marcas").carousel();
    },
    obtenertextoSingular(texto) {
      if (texto.includes(",")) {
        return "Platillos estrella:";
      } else {
        return "Platillo estrella:";
      }
    },
    irid() {
      // console.log(window.location.href)
      const url = window.location.href;
      const lastParam = url.split("#").slice(-1)[0];
      // console.log(lastParam)
    },
  },
  computed: {
    ...mapState([
      "grg_var_scroll",
      "grg_var_view_port_md",
      "grg_var_transparencia",
    ]),
  },
  mounted() {
    this.activarSlider();
  },
  created() {
    this.grgFuncionChangeTransparencia(true);
    this.irid();
  },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `GRG | Nuestras Marcas`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.row {
    margin: 0 -15px;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
.grg_transparencia {
  filter: brightness(0.6);
}
.grg_container_trasparencia {
  background: linear-gradient(
    180deg,
    #0000 0%,
    #0000003b 24%,
    #000000e3 76%,
    #000,
    #000,
    #000
  );
  position: absolute;
  bottom: 0;
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
  z-index: 2;
}
.grg_container_principal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_titulo_pagina {
  position: absolute;
  margin: 1% 0;
  bottom: 0;
  z-index: 10;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

/*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
.grg_container_ficha_marca {
  scroll-margin-top: 10px;
  padding: 3.5rem 0;
}
.grg_container_ficha_marca:nth-child(odd) {
  background-color: #2b2b2b;
  color: #a1988f;
}
.grg_container_ficha_marca:nth-child(even) {
  background-color: #726d69;
  color: #2b2b2b;
}
.grg_img_principal_marca {
  max-width: 260px;
  height: 260px;
  object-fit: cover;
}
.grg_container_imagen_platillo,
.grg_container_redes_sociales {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.grg_container_imagen_platillo_comodin:before {
  content: "";
  position: absolute;
  top: -10px;
  border: 1px #fff solid;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.grg_container_imagen_logo_marca {
  position: absolute;
  width: 110%;
  top: -30%;
  left: -5%;
}
.grg_container_datos {
  display: flex;
  align-items: baseline;
  padding: 0.2rem 0;
}
.grg_container_icon_category {
  margin: 0 10px;
}
.grg_container_redes_sociales_global {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.grg_container_elemento_texto_siguenos {
  text-align: center;
  width: 100%;
}
.grg_img_icon_redes {
  width: 50px;
  margin: 0 6px 0;
  transition: all 0.3s;
}
.grg_img_icon_redes:hover {
  transform: scale(1.2);
}
.grg_container_informacion_general,
.grg_container_datos_globales,
.grg_container_redes_sociales_global {
  padding: 2rem 0;
}
.grg_p_6 {
  padding: 6rem 0;
}
.grg_p_6_1 {
  padding: 6rem 0 0;
}
.grg_utileria_flechas_abajo:after {
  content: url("../assets/img/utilerias/flecha_abajo.webp");
  position: absolute;
  color: #fff;
  z-index: 50;
  bottom: -25px;
  width: 100%;
  text-align: center;
  transform: scale(0.7);
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/

@media (min-width: 1200px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
  .grg_container_titulo_descripcion {
    max-width: 95%;
  }
  .grg_container_datos_globales {
    max-width: 100%;
    padding: 2rem 5rem 0 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
}
@media (min-width: 992px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */
  .grg_section_principal {
    margin-top: -95px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion principal */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
  .grg_img_principal_marca {
    max-width: 320px;
    height: 320px;
  }
  .grg_container_ficha_marca:nth-child(odd) .grg_container_titulo_descripcion {
    margin-left: -8%;
  }
  .grg_container_ficha_marca:nth-child(even) .grg_container_titulo_descripcion {
    margin-left: 8%;
  }
  .grg_container_ficha_marca:nth-child(even) .grg_container_datos_globales {
    margin-left: 16%;
  }
  .grg_container_redes_sociales_global {
    flex-direction: row;
  }
  .grg_container_elemento_texto_siguenos {
    width: min-content;
    margin: 0 4rem 0 0;
  }
  .grg_img_icon_redes {
    width: 60px;
  }
  .grg_container_redes_sociales {
    width: 50%;
  }
  [data-utileria="flechas_derecha"]:before {
    content: url("../assets/img/utilerias/flechas_blancas.webp");
    position: absolute;
    bottom: 20%;
    right: -35%;
    transform: scale(0.8);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
}
@media (min-width: 768px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
  .grg_container_ficha_marca:nth-child(even) .container-fluid .row {
    display: flex;
    flex-direction: row-reverse;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
  .grg_container_elemento_texto_siguenos {
    padding: 0 0 1rem 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
}
@media (max-width: 767.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
  .grg_container_informacion_general,
  .grg_container_datos_globales,
  .grg_container_redes_sociales_global {
    padding: 1rem 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
}
@media (max-width: 575.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
  .grg_container_ficha_marca {
    padding: 3rem 0;
    scroll-margin-top: 3em;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para seccion Marcas >>>>>>>>>>>>>>>>>>>>*/
}
</style>